import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import LogoHeader from '../components/logoheader/logoHeader'
import '../css/specials.css';

export default function Specials() {
	return (
    	<>
      		<LogoHeader/>
      		<div className="specialsCenterCol">
	      		<Container className="specialsContainer">
	     			<Row>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					<h3 className="specialsHeader">Lunch Specials <br/> Monday to Friday <br/> ( 11 AM to 3 PM )</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					6 Wings, Large Fries & Soda - $12.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					2 Slices of Pizza & Soda - $8.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					1 Pizza Slice, Salad & Soda - $10.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					2 Slices of Pizza & Domestic Beer - $9.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					2 Slices of Pizza & Premium Beer - $11.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Sandwich, Soda - $15.99
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Pizza by the slice (All day) - $3
	     				</Col>
	     			</Row>
	     		</Container>
     		</div>
      	</>
    );
}	