import React from 'react';
import Stack from 'react-bootstrap/Stack';
import logoImage from '../../images/logo4.png'; 
import './logoHeader.css';

class LogoHeader extends React.Component {
  render(){
    
  	return (
    <>
     <div className="sticky-top" id="logoHeader">
      <a href="/">
        <img className="headerLogo" src={logoImage}/>
      </a>
     </div>
    </>)
  }
}

export default LogoHeader;
