import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import LogoHeader from '../components/logoheader/logoHeader'

export default function Catering() {
	return (
    	<>
      		<LogoHeader/>
      		<div className="specialsCenterCol">
	      		<Container className="specialsContainer">
	     			<Row>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					<h3 className="specialsHeader">Appetizers</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Chicken Wings - Half Tray $75, Full $140
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Mozzarella Sticks - Half Tray $60, Full $110
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Zucchini Sticks - Half Tray $50, Full $110
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Spinach Pinwheels - Half Tray $65, Full $120
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Pepperoni Pinwheels - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Meatballs - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Stromboli bites - Half Tray $85, Full $150
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Dough Nugs - Half Tray $25, Full $40
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Garlic Knots - Half Tray $35, Full $60
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Dough Nugs - Half Tray $25, Full $40
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Bread Sticks - Half Tray $30, Full $55
	     				</Col>
	     			</Row>
	     			<Row>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					<h3 className="specialsHeader">Salads</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					OC House Salad - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Italian Chopped Salad - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Antipasto - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Greek Salad - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Caesar Salad - Half Tray $45, Full $80
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Tossed Sald - Half Tray $30, Full $55
	     				</Col>
	     			</Row>
	     			<Row>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					<h3 className="specialsHeader">Pasta</h3>
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Penne & Meatballs - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Chicken Perm Pasta - Half Tray $70, Full $130
	     				</Col>
	     				<Col sm="12" med="12" lg="12" xl="12" className="specialsMenuItem">
	     					Penne w/ Marinara - Half Tray $50, Full $90
	     				</Col>
	     			</Row>
	     		</Container>
     		</div>
      	</>
    );
}	