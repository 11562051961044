import React, { useState, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Rating from '../components/rating/rating';
import { Redirect, useNavigate } from 'react-router-dom';

const DineInServiceRating = () => {
  useEffect(() => {
    // Replace 'https://example.com' with the external URL you want to navigate to
    const externalUrl = 'https://jw0qyz4qgcp.typeform.com/to/YQlvhZeL';

    // Use the window.location.replace method to navigate to the external URL
    window.location.replace(externalUrl);
  }, []); // The empty dependency array ensures this effect runs only once, similar to componentDidMount

  // The component doesn't need to render anything, as it redirects immediately
  return null;
};

export default DineInServiceRating;

// export default DineInServiceRating;