import wingsfriesimg from '../images/wings-fries.jpeg';
import pizzasaladimg from '../images/ps2.jpeg';

const menu={
  'starters': [
     {
      "title": "Chicken Wings",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "19.99 for 10 pcs, $34.99 for 20 pcs",
      "desc": "Made with Sauce of your choice. Ranch or Blue Cheese. ( $2 extra for Oven Baked )",
    },
    {
      "title": "Chicken Chunks/tenders",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "9.49 for 5 pcs, $15.99 for 10 pcs",
      "desc": "Boneless Skinless breaded & battered w/ wheat flour",
    },
    {
      "title": "Garlic Knots",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "8.49",
      "desc": "Made with fresh dough tied and baked with fresh garlic butter and parm. Served with a side of Marinara"
    },
    {
      "title": "Mozrella Sticks",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "13.99 for 10 pcs, $23.99 for 20 pcs",
      "desc": "With Marinara"
    },
    {
      "title": "Spinach Pinwheel",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "10.99",
      "desc": "Spinach, Mozzarella, Parmesan Cheese and Garlic rolled up Served with side of Marinara Sauce."
    },
    {
      "title": "Spinach Pinwheel With Pepperoni",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "12.99",
      "desc": "Spinach, Mozzarella, Parmesan Cheese and Garlic rolled up Served with side of Marinara Sauce With Pepperoni."
    },
    {
      "title": "Garlic Bread - 8 pcs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "6.99",
      "desc": "Served with w/ Marinara",
    },
    {
      "title": "Garlic Bread w/ Cheese - 8 pcs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99",
      "desc": "Served with w/ Marinara",
    },
    {
      "title": "French Fries Basket",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "6.99",
      "desc": ""
    },
    {
      "title": "Bread Stix - 8 to 10pcs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99",
      "desc": "10 Piece Bread Stix with Marinara Sauce."
    },
    {
      "title": "Fried Zucchini or Jalapeno poppers or Onion Rings",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "8.99"
    },
    {
      "title": "Meatballs - 2pcs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99",
      "desc": "Meatballs and Mozzarella with Marinara."
    },
    {
      "title": "Meatball Sliders - 2pcs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99",
      "desc": "Meatballs and Mozzarella with Marinara."
    }
  ],
  "salads": [
    {
      "title": "Antipasto",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "11.99 (S)/ $ 13.99 (L)",
      "desc": "Black Olives, Pepperoni, Salami, Ham, Tomato, Pepperoncini & Mozzarella Cheese."
    },
    {
      "title": "BBQ Chicken Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "11.99 (S)/ $ 13.99 (L)",
      "desc": "BBQ Chicken, Garbanzo beans,  Tomato, Red Onions, Mozzarella Cheese"
    },
    {
      "title": "Italian Chopped Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "11.99 (S)/ $ 13.99 (L)",
      "desc": "Black Olive, Salami, Garbanzo Beans, Tomato, Red Onion & Mozzarella Cheese."
    },
    {
      "title": "O.C. Signature Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "11.99 (S)/ $ 13.99 (L)",
      "desc": "Tomato, Salami, Olive, Mushroom, Pepperoncini & Mozzarella Cheese."
    },
    {
      "title": "Caesar Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "9.99 (S)/ $ 11.99 (L)",
      "desc": "Croutons & Parmesan."
    },
    {
      "title": "Greek Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "10.99 (S)/ $ 12.99 (L)",
      "desc": "Black Olive, Cucumber, Tomato, Red Onion, & Feta Cheese."
    },
    {
      "title": "Tossed Salad",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "8.99 (S)/ $ 10.99 (L)",
      "desc": "Tomato, Cheese & Croutons. $"
    }
  ],
  "pasta": [
    {
      "title": "Buttered Noodles",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "9.99",
      "desc": ""
    },
    {
      "title": "Penne or Spaghetti with Meatballs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "15.99",
      "desc": ""
    },
    {
      "title": "Penne or Spaghetti with Marinara",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "11.99",
      "desc": ""
    },
    {
      "title": "Fettuccine with Alfredo",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "13.99",
      "desc": ""
    },
    {
      "title": "Fettuccine with Alfredo & Chicken",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "16.99",
      "desc": ""
    },
  ],
  "pizza": {
    "speciality": [
      {
        "title": "O.C. Signature/House Pizza",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 18.99 - SM 6 slices \n $ 23.99  - MED 8 slices \n $ 28.99  - LG 10 slices \n $ 32.99  - XL 12 slices \n $ 34.99  - JUMBO 14 slices \n",
        "desc": "Pepperoni, Mushrooms, Red Onions, Green Peppers, Black Olives & Sausage with Extra Cheese."
      },
      {
        "title": "BBQ Chicken Delight",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 18.99 - SM 6 slices \n $ 23.99  - MED 8 slices \n $ 28.99  - LG 10 slices \n $ 32.99  - XL 12 slices \n $ 34.99  - JUMBO 14 slices \n",
        "desc": "Chicken Brest, Red Onion, Cilantro, Pineapple"
      },
      {
        "title": "The Meat Lover",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 18.99 - SM 6 slices \n $ 23.99  - MED 8 slices \n $ 28.99  - LG 10 slices \n $ 32.99  - XL 12 slices \n $ 34.99  - JUMBO 14 slices \n",
        "desc": "Pepperoni, Canadian Bacon, Meatballs & Sausage."
      },
      {
        "title": "Pesto Pizza",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 18.99 - SM 6 slices \n $ 23.99  - MED 8 slices \n $ 28.99  - LG 10 slices \n $ 32.99  - XL 12 slices \n $ 34.99  - JUMBO 14 slices \n",
        "desc": "Made with Pesto sauce ( instead of traditional pizza sauce ), topped with Tomatoes, Spinach, Artichoke"
      },
      {
        "title": "Hawalian Delight",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 16.99 - SM 6 slices \n $ 21.99  - MED 8 slices \n $ 26.99  - LG 10 slices \n $ 30.99  - XL 12 slices \n $ 33.99  - JUMBO 14 slices \n",
        "desc": "Canadian Bacon, Pineapple & Bacon."
      },
      {
        "title": "Vegetarian Pizza Special",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 16.99 - SM 6 slices \n $ 21.99  - MED 8 slices \n $ 26.99  - LG 10 slices \n $ 30.99  - XL 12 slices \n $ 33.99  - JUMBO 14 slices \n",
        "desc": "Mushrooms, Onions, Black Olives, Green Peppers & Tomato"
      },
      {
        "title": "3 Cheese White Pizza",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 16.99 - SM 6 slices \n $ 21.99  - MED 8 slices \n $ 26.99  - LG 10 slices \n $ 30.99  - XL 12 slices \n $ 33.99  - JUMBO 14 slices \n",
        "desc": "Olive Oil, Garlic, Ricotta, Mozzarella Cheese & Parmesan, Served with a side of Marinara"
      },
      {
        "title": "Pizza Margherita",
        "url": wingsfriesimg,
        "thumbnailUrl": wingsfriesimg,    
        "price": "$ 16.99 - SM 6 slices \n $ 21.99  - MED 8 slices \n $ 26.99  - LG 10 slices \n $ 30.99  - XL 12 slices \n $ 33.99  - JUMBO 14 slices \n",
        "desc": "Fresh Tomato, Basil & Garlic."
      },
    ],
    "buildYourOwn": {
      "toppings": ['Artichoke, Banana Pepper, Basil, Black Olives, Garlic, Green Pepper, Jalapeno, Mushroom, Pineapple, Red  Onion, Spinach, Tomato, Ricotta, Bacon, Canadian Bacon, Chicken, Meatballs, Pepperoni, Salami, Sausage'],
      "size": [
        {
          "title": 'Small 10" - $11.99 + $1.25 per topping',
        },
        {
          "title": 'Medium 12" - $15.99 + $1.75 per topping',
        },
        {
          "title": 'Large 14" - $20.99 + $2.75 per topping',
        },
        {
          "title": 'XLarge 16" - $23.99 + $3.25 per topping',
        },
        {
          "title": 'Jumbo 18" - $28.99 + $3.75 per topping',
        }
      ]
    }
  },
  "calzone": [
    {
      "title": "Sicilian Calzone",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "16.99",
      "desc": " Stuffed with Mozzarella Cheese, Ricotta, Pepperoni, Italian Sausage, Red Onions, Mushrooms, Black Olives & our Special Sauce"
    },
    {
      "title": "Meat Lover Calzone",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "16.99",
      "desc": "Stuffed with Mozzarella Cheese, Ricotta, Sausage, Pepperoni, Ham & our Special Sauce."
    },
    {
      "title": "Stromboli",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "16.99",
      "desc": "Pepperoni, Mushrooms, Red Onions, Green Peppers, Black Olives & Sausage with Extra Cheese."
    },
    {
      "title": "Build Your Own Calzone",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "9.99",
      "desc": "$0.99 for each additional toppings"
    },
  ],
  "sandwiches": [
    {
      "title": "OC Cheesesteak",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "8.99 for 6\", $14.99 for 12\"",
      "desc": "Thinly sliced 100% pure beef slow cooked to perfection Mayo, Sautéed Onion &Bell Pepper and Melted Provolone Cheese"
    },
    {
      "title": "Meatball Sandwich",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99 for 6\", $13.99 for 12\"",
      "desc": "Meatballs covered with Marinara Sauce & loads of Melted Mozzarella Cheese"
    },
    {
      "title": "BBQ Chicken Sandwich",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99 for 6\", $13.99 for 12\"",
      "desc": "BBQ Chicken, Lettuce, Tomato, Onions, Melted Mozzarella Cheese , Mayo, Mustard & Italian Dressing"
    },
    {
      "title": "Grilled Chicken Sandwich",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99 for 6\", $13.99 for 12\"",
      "desc": "Grilled Chicken, Lettuce, Tomato, Onions, Melted Mozzarella Cheese , Mayo, Mustard & Italian Dressing"
    },
    {
      "title": "Grinder Sandwich",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.99 for 6\", $13.99 for 12\"",
      "desc": "Pepperoni, Salami, Ham, Mozzarella Cheese, Lettuce, Tomato, Crunchy Onions, Mayo, Mustard & Italian Dressing"
    }
  ],
  "dessert": [
    {
      "title": "Dough-Nugs",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.95"
    },
    {
      "title": "Churros (4 pc)",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "8.95"
    },
    {
      "title": "Root Beer Float ",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "7.95"
    },
    {
      "title": "Cookies",
      "url": wingsfriesimg,
      "thumbnailUrl": wingsfriesimg,    
      "price": "2.95"
    }
  ]
  };
export default menu;