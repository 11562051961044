import React, { useState } from "react";

export default function Schedulelogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Example hardcoded credentials (for demo purposes)
    const validUsername = "user";
    const validPassword = "password";

    if (username === validUsername && password === validPassword) {
      alert("Login successful!");
      window.location.href = 'https://docs.google.com/document/d/1nQN5A4_1J_TSC5FCnAiNGdOLR7nXd0FSC2-68lz0sU8/edit?usp=sharing';
      // In a real app, you would redirect to another page or dashboard
    } else {
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <>
         <div className="login-container">
            <h1>Login</h1>
            <form onSubmit={handleSubmit} className="login-form">
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <div>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Login</button>
            </form>
          </div>
    </>
  );
}
