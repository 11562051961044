import Stack from 'react-bootstrap/Stack';
import HorizontalSlider from 'react-horizontal-slider'
import 'react-horizontal-slider/build/main.css';

import offers from '../data/homepage_offers'

import backgroundStoreImage from '../images/bg_4.png'; 
import LogoHeader from '../components/logoheader/logoHeader'

import '../css/homepage.css';

import {Container, Row, Col} from 'react-bootstrap';
import {
 Button, Nav, NavItem, NavLink,  Card, CardImg, CardText, CardBody,
 CardTitle, CardSubtitle, ListGroup, ListGroupItem
} from 'reactstrap';

import FlyerSummerSpecials from '../images/flyer_summer_specials.png'; 
import FlyerJuly4Img from '../images/flyer_july_4.png'; 
import FlyerHappyHourImg from '../images/flyer_happy_hour.png'; 
import FlyerWeekdaySpecial from '../images/flyer_weekday_specials.png'; 
import FlyerPizzaDeal from '../images/flyer_pizza_10_per_off.gif'; 
import Flyer10Discount from '../images/flyer_10_discount.png'; 
import Flyer5Discount from '../images/flyer_5_discount.png'; 
import FlyerWingsDeal from '../images/flyer_wings_deal.png'; 
import FlyerHappyHour from '../images/flyer_happy_hour.gif';
import FlyerLunchSpecial from '../images/flyer_lunch_special.gif'; 

export default function Homepage() {

  const centerContainer = {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent:'center',
      // height: '80vh',
      width: '100% !important',
      // backgroundSize: '100% auto', /* Set width to 100% and maintain aspect ratio */
      backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center',
      margin: '1vh 0',
      padding: '5vh 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${backgroundStoreImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
  };
  
  return (
    <>
      <LogoHeader/>
      <div className="showCase">
        <Container className="container-fluid">
          <Row>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>Free Gelato Sandwich</h1>
               <p>
                 Get a Free Gelato Sandwich (a $5.99 value) with any order of $25 or more!
                 <br/><br/>Simply add the item to your cart to enjoy this promotion. This offer cannot be combined with other discounts and available for online order only.
                 <br/><br/>
                 <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://order.online/store/oc-pizza-mission-viejo-22922068/"> 
                  <Button className="specialButton">Order Now</Button>
                 </a>
               </p>
             </div>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>Online Order Discount: 40% Off</h1>
               <p>
                 20% off all online orders of $10 or more (up to $5 off).
                 <br/><br/>Apply promo code <b>MPUXD</b> at checkout.
                 <br/><br/>
                 <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://order.online/store/oc-pizza-mission-viejo-22922068/"> 
                  <Button className="specialButton">Order Now</Button>
                 </a>
                 <span class="speaciasTandC">
                   <br/>*Minimum $10 order amount required. Limited time offer. Discount available to apply at checkout. This offer cannot be combined with any other discounts. Online prices may vary from our in-store prices.
                   <br/>
                 </span>
               </p>
             </div>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>Monday–Wednesday Pizza Special: 20% Off</h1>
               <p>
                 <br/>
                 <br/>
                 Dine-in only
                 <br/>
                 <br/>
                 Must present this offer using the OC Pizza website on your phone before placing an in-person order. 
                 Photos, screenshots, or printed copies will not be accepted.
                 <br/>
                 <br/>
                 <span>
                  <a alt="Pizza Menu" className="homepageMainLinks" href="/menu/pizza"> 
                    <Button className="specialButton dineInSpecialBoxButton">View Pizza Menu</Button>
                  </a>
                  <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUqDggCEEUYJxg7GIAEGIoFMgYIABBFGDwyBggBEEUYPDIOCAIQRRgnGDsYgAQYigUyBggDEEUYOTIVCAQQLhgnGK8BGMcBGIAEGIoFGI4FMgkIBRBFGDsYgAQyBggGEEUYQTIGCAcQRRg8qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=us&sa=X&geocode=Kf2Btvt36dyAMb6UgUDbLGMb&daddr=25102+Marguerite+Pkwy+STE+A,+Mission+Viejo,+CA+92692"> 
                    <Button className="specialButton dineInSpecialBoxButton">Visit Now</Button>
                  </a>
                 </span>
               </p>
             </div>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>$30 Off Your Next Catering Order</h1>
               <p>
                 <br/>
                 <br/>
                 Order value must be $200 or more.
                 <br/>
                 <br/>
                 Orders must be placed online <a href="https://order.online/store/oc-pizza-mission-viejo-22922068">here</a>
                 <br/>
                 <br/>
                 Apply promo code <b>HGBED</b> at checkout.
                 <br/>
                 <br/>
                 <span>
                  <a alt="Pizza Menu" className="homepageMainLinks" href="https://order.online/store/oc-pizza-mission-viejo-22922068"> 
                    <Button className="specialButton dineInSpecialBoxButton">Order Now</Button>
                  </a>
                 </span>
               </p>
             </div>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>Breakfast Special For NFL/Football Season</h1>
               <p>
                 Breakfast burrito & Breakfast pizza - open on Sunday's at 9:30 AM
               </p>
             </div>
            </Col>
            {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
             <div className="showCaseCol showCaseColText">
               <h1>Daily Specials for Dine-in Only</h1>
               <p>
                 <br/>
                 Sunday - Tuesday - 10% off
                 <br/>
                 Wednesday - Thursday - 7.5% off
                 <br/>
                 Friday - 5% off
                 <br/>
                 Saturday - Surprised discount
                 <br/>
                 <br/>COUPON CODE: WEEKDAYUP10
                 <br/>
                 <span>
                  <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://www.google.com/maps?gs_lcrp=EgZjaHJvbWUqDggCEEUYJxg7GIAEGIoFMgYIABBFGDwyBggBEEUYPDIOCAIQRRgnGDsYgAQYigUyBggDEEUYOTIVCAQQLhgnGK8BGMcBGIAEGIoFGI4FMgkIBRBFGDsYgAQyBggGEEUYQTIGCAcQRRg8qAIAsAIA&um=1&ie=UTF-8&fb=1&gl=us&sa=X&geocode=Kf2Btvt36dyAMb6UgUDbLGMb&daddr=25102+Marguerite+Pkwy+STE+A,+Mission+Viejo,+CA+92692"> 
                    <Button className="specialButton dineInSpecialBoxButton">Visit Now</Button>
                  </a>
                  <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://docs.google.com/forms/d/e/1FAIpQLSdVeVj8qmgZThywGcMeokY1pzn5elvQrdQpJnwtmg3iPNkJYg/viewform"> 
                    <Button className="specialButton dineInSpecialBoxButton">Make Reservation</Button>
                  </a>
                 </span>
                 <span class="speaciasTandC">
                   <br/>*Minimum $20 order required for pizza, pasta, salads, sandwiches, calzones, and appetizers only. Offer valid on selected items within these categories. Limited time offer. Must present this coupon at time of order.
                 </span>
               </p>
             </div>
            </Col>*/}
          </Row>
        </Container>
      </div>
      <div style={centerContainer}>
        <Container  className="container-fluid">
          <Row>
            {/*<Col xs="12" sm="12" md="12"  lg="12" xl="12" className="homepageCol">
              <a target="_blank" href="https://order.toasttab.com/online/oc-pizza-mission-viejo-25104-marguerite-parkway" className="homepageButtonHref"> 
                Order Now 
              </a>
            </Col>*/}
            {/*<Col xs="12" sm="12" md="12"  lg="12" xl="12" className="homepageCol homepageColLinks">
              <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://order.toasttab.com/online/oc-pizza-mission-viejo-25104-marguerite-parkway"> 
                Order Now 
              </a>
            </Col>*/}
            <Col xs="12" sm="12" md="12"  lg="12" xl="12" className="homepageCol homepageColLinks">
              <a target="_blank" alt="Order Now" className="homepageMainLinks" href="https://order.online/store/oc-pizza-mission-viejo-22922068/"> 
                Order Now 
              </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a href="/menu" alt="Menu" className="homepageMainLinks"> Menu </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a href="/drinks-happy-hour" alt="Drinks & Happy Hour" className="homepageMainLinks"> Drinks & Happy Hour </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a href="/specials" alt="Lunch & Daily Specials" className="homepageMainLinks"> 
                Lunch & Daily Specials 
              </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a href="https://www.ezcater.com/catering/pvt/oc-pizza-3?fcv=1" alt="Catering" className="homepageMainLinks"> 
                Catering
              </a>
            </Col>
            {/*<Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a target="_blank" alt="Reviews" href="https://tally.so/r/w4xVRd" className="homepageMainLinks"> Leave Us a Review </a>
            </Col>*/}
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="homepageCol homepageColLinks">
              <a target="_blank" alt="Join Rewards" href="https://www.toasttab.com/oc-pizza-mission-viejo-25104-marguerite-parkway/rewardsSignup" className="homepageMainLinks"> Join or Check Rewards</a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="showCase">
        <Container className="container-fluid">
          <Row>
            {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerPizzaDeal}/>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <a href="https://tally.so/r/w4xVRd"  alt="10% Discount" className="contactHref">
                <img className="showCaseCol" src={Flyer5Discount}/>
              </a>
            </Col>*/}
            {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerHappyHourImg}/>
            </Col>*/}
           {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerJuly4Img} alt="pizza, beer, wine, bar open on july 4th for dine-in, pickup and delivery"/>
           </Col>*/}
           {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerWeekdaySpecial} alt="daily specials and discounts for pizza, beer, wine, bar and happy hour"/>
            </Col>*/}
            {/*<Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerSummerSpecials}/>
            </Col>*/}
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <img className="showCaseCol" src={FlyerWingsDeal}/>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <a href="/drinks-happy-hour" alt="Happy Hour" className="contactHref">
                <img className="showCaseCol" src={FlyerHappyHour} alt="bar, sports bar, happy hour"/>
              </a>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="homepageCol">
              <a href="/specials" alt="Lunch Special" className="contactHref">
                <img className="showCaseCol" src={FlyerLunchSpecial} alt="daily lunch specials and discounts"/>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contactFooter">
        <Container>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="contactCol">
              <b>Hours</b><br/>
              Monday: 12PM to 9PM<br/>
              Tuesday: 11AM to 9PM<br/>
              Wednesday: 11AM to 9PM<br/>
              Thursday: 11AM to 9PM<br/>
              Friday: 11AM to 10PM<br/>
              Saturday: 11AM to 10PM<br/>
              Sunday: 11AM to 9PM (open at 9:30am during football/nfl season)<br/>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="contactCol">
              <a target="_blank" href="tel:+19494298888"  alt="Phone" className="contactHref"> Phone: +1-949-429-8888 </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="contactCol">
              <a target="_blank" href="mailto:ocpizzakitchen@gmail.com" alt="Email" className="contactHref"> Email: ocpizzakitchen@gmail.com </a>
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="contactCol">
              <a target="_blank" alt="Address" href="https://www.google.com/maps/place/OC+PIZZA+Mission+Viejo/@33.5979061,-117.6571249,15z/data=!4m2!3m1!1s0x0:0x1b632cdb408194be?sa=X&ved=2ahUKEwiu8-r8wJWEAxWtkO4BHWd6CIkQ_BJ6BAgNEAA" className="contactHref"> 25102 Marguerite Pkwy, Suite A, Mission Viejo, CA, 92692 </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="contactFooter">
        <Container>
          <Row>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="contactCol">
              <a target="_blank" alt="Fundraise" href="https://docs.google.com/forms/d/e/1FAIpQLSesKpMMCV_wBCTMrFlToO1Q9-iNWn7HqKypIhNbdCLTgJfSxQ/viewform"  className="contactHref"> Fundraising </a>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="contactCol">
              <a target="_blank" alt="Sponsorship" href="https://docs.google.com/forms/d/e/1FAIpQLSe7Xk06bwHXXJY60p2XXWJ-8h3WbO6w7MWL-3kYaSMN0uS_ZA/viewform"  className="contactHref"> Sponsorship </a>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" xl="4" className="contactCol">
              <a target="_blank" alt="Job Application" href="https://docs.google.com/forms/d/e/1FAIpQLSetoZyG7wwLYwv-MNazKHrevsxKca3VQCXS5VTkQfQ7gVheHA/viewform"  className="contactHref"> Job Application / Careers </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
