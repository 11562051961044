import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import LogoHeader from '../components/logoheader/logoHeader'
import '../css/drinks.css';
import {
  Nav, NavItem, NavLink,  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, ListGroup, ListGroupItem
} from 'reactstrap';

class Drinks extends React.Component {
	constructor(props)
	  {
	    super(props);
	    const { activeSubMenu } = this.props;
	   
	    this.state={
	      activeSubmenu:activeSubMenu
	    }

	    this.activateMenu = this.activateMenu.bind(this);
	  }

	activateMenu(subMenu){
		this.setState({
			activeSubmenu:subMenu
		});
		return false;
  	}
	render(){
		return (
	    	<>
	      		<LogoHeader/>
	      		<div>
		      		<Container>
		      			<Row className="sticky-top" id="menuRow">
			     			<Col>
					     		<Nav pills justified horizontal="center">
					     			<NavItem key='beer'>
								    	<NavLink  href="#" className={(this.state.activeSubmenu == 'beer' ? 'active' : '')}
								    		onClick={this.activateMenu.bind(null, 'beer')}
								    	>
								      		Beer
								    	</NavLink>
								  	</NavItem>
					     			<NavItem key='wine'>
								    	<NavLink  href="#" className={(this.state.activeSubmenu == 'wine' ? 'active' : '')}
								    		onClick={this.activateMenu.bind(null, 'wine')}
								    	>
								      		Wine
								    	</NavLink>
								  	</NavItem>
								  	<NavItem key='happy-hour'>
								    	<NavLink  href="#" className={(this.state.activeSubmenu == 'happy-hour' ? 'active' : '')}
								    		onClick={this.activateMenu.bind(null, 'happy-hour')}
								    	>
								      		Happy Hour
								    	</NavLink>
								  	</NavItem>
								</Nav>
							</Col>
			     		</Row>
			     		{(this.state.activeSubmenu == "wine") &&
			     			<Row>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					<h3 className="drinksHeader">House Red Wines</h3>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Pinot Noir Black Ridge  -     $7.00(GL) $21(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Cabernet Black Ridge      -	 $8.00(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					<h3 className="drinksHeader">Speciality Red Wines</h3>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Pinot Noir San Simeon     - $13(GL) $39(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Pinot Noir 41 Highland	  - $8(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Pinot Noir Black Ridge	  - $8(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Cabernet Maddalena	    - $12(GL) $36(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Cabernet 41 Highland	  - $8(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Merlot Crane Lake          - $8(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Red Blend 41 Highland    - $10(GL) $30(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					<h3 className="drinksHeader">House White Wines</h3>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Chardonnay Black Ridge -   $8.00(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Sauvignon blanc Tierra   -   $8.00(GL) $24(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
									<h3 className="drinksHeader">Speciality White Wines</h3>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Chardonnay Maddalena       -   $9(GL) $27(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Sauvignon blanc San Simeon -   $11(GL) $34(BTL)
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Pinot Grigio - $9(GL) $27(BTL)
			     				</Col>
			     			</Row>
			     		}
			     		{(this.state.activeSubmenu == "beer") &&
			     			<Row>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					$5.50 (Pint), $10(Large), $18.99(Pitcher)<br/>
			     					Coors Lt<br/>Michelob Ultra						
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					<br/><br/>$7.50 (Pint), $15(Large), $26.50(Pitcher)<br/>
			     					Modelo<br/>
			     					Stella<br/>
			     					805<br/>
			     					Cougar Juice<br/>
									HuckleBerry - BreweryX<br/>
									Truly<br/>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					<br/><br/>$8.50 (Pint), $17(Large), $28.99 (Pitcher)<br/>
			     					Blood Orange - Cali Squeeze<br/>
			     					Blue Moon<br/>
									Double Nickel - Docent<br/>
									Harland Hazy IPA<br/>
									Racks & Balls IPA<br/>
									Sierra Nevada Pale Ale<br/>
			     				</Col>
			     			</Row>
			     		}
			     		{(this.state.activeSubmenu == "happy-hour") &&
			     			<Row style={{padding: "20px 20px 50px"}}>
				      		<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
					     			<h3 className="drinksHeader">Coors Lt</h3>
					     		</Col>
				      		<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
										Pint $4<br/>
										Schooner $7<br/>
										Pitcher $15<br/>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
				     				<h3 className="drinksHeader">Premium Beers</h3>
				     			</Col>
				      		<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
										Pint $6<br/>
			 							Schooner $10<br/>
										Pitcher $19<br/>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
				     				<h3 className="drinksHeader">Glass of Wine for $6</h3>
				     			</Col>
				     			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
										Cabernet Black Ridge<br/>
										Pinot Noir Black Ridge<br/>
										Merlot Crane Lakes<br/>
										Chardonnay Black Ridge<br/>
										Sauvignon Blanc Tierra<br/>
										Pinot Grigios
			     				</Col>
				      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
				     				<h3 className="drinksHeader">Appetizers for $5.99</h3>
				     			</Col>
				      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					Garlic Knots<br/>
										Meatball Sliders<br/>
										Fried Zucchini<br/>
										Jalapeno Poppers<br/>
										Onion Rings<br/>
										Tots<br/>
										Mozzarella Sticks<br/>
			     				</Col>
			     				<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
				     				<h3 className="drinksHeader">Pizza for $10.99</h3>
				     			</Col>
				      			<Col sm="12" med="12" lg="12" xl="12" className="drinksMenuItem">
			     					10" One Topping Pizza 
			     				</Col>
		      				</Row>
	      				}
		     		</Container>
	     		</div>
	      	</>
	    );
	}
}	

export default Drinks;