import * as React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';



class DineInServiceRating extends React.Component {
  constructor(props)
  {
    super(props);
    this.state={
       
    }

    
  }

  render(){
  	return (
    	<>
    		<Container>
    			<Row>
    				<Col xs="12">
    					{this.props.label}
    				</Col>
    			</Row>
    			<Row>
    				<Col xs="12">
    					<Rating name="rating" size="large"/>
    				</Col>
    			</Row>
    		</Container>
    	</>
  	)
  };

}

export default DineInServiceRating;